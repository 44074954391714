import { Grid, IconButton } from '@achieve/sunbeam'
import { ChevronLeft, ChevronRight } from 'react-feather'
import styles from './CarouselV2.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useContext, useRef, useState } from 'react'
import { useEventScope } from 'hooks/useEventScope'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { DotIndicators } from 'components/DotIndicators'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'

const CAROUSEL_DIRECTIONS = {
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
}

const getItemDetails = ({ itemIndex, activeIndex, listLength }) => {
  return {
    // isActive=true when the activeIndex from state matches the item itemIndex
    isActive: itemIndex === activeIndex,

    // When the current activeIndex is the first item in the list, isPrevious=true if it
    // is the last item in the list. Otherwise, isPrevious=true if this item's itemIndex is
    // one less than the activeIndex.
    isPrevious: activeIndex === 0 ? itemIndex === listLength - 1 : itemIndex === activeIndex - 1,

    // When the current activeIndex is the last item in the list, isNext=true if it is the
    // first item in the list. Otherwise, isNext=true if this item's itemIndex is one more
    // than the activeIndex.
    isNext: activeIndex === listLength - 1 ? itemIndex === 0 : itemIndex === activeIndex + 1,
  }
}

const CarouselButton = ({ direction = '', onClick }) => {
  if (!direction) return

  const isPrevious = direction === CAROUSEL_DIRECTIONS.PREVIOUS

  return (
    <div>
      <IconButton
        onClick={() => onClick()}
        color="secondary"
        aria-label={`Display ${isPrevious ? 'Previous' : 'Next'} item in carousel`}
      >
        {isPrevious ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </div>
  )
}

const CarouselItemMessage = ({ children, activeIndex, itemIndex, listLength }) => {
  const { isActive, isPrevious, isNext } = getItemDetails({
    activeIndex,
    itemIndex,
    listLength,
  })

  return (
    <Grid
      data-active={isActive}
      data-next={isNext}
      data-previous={isPrevious}
      className={styles['message']}
      container
      direction="column"
      alignItems="center"
    >
      {children}
    </Grid>
  )
}

const CarouselItem = ({
  children,
  activeIndex,
  itemIndex,
  listLength,
  onPreviousClick,
  onNextClick,
}) => {
  const { isActive, isPrevious, isNext } = getItemDetails({
    activeIndex,
    itemIndex,
    listLength,
  })

  const handleClick = (e) => {
    if (isActive) return
    if (isPrevious) return onPreviousClick(e)
    if (isNext) return onNextClick(e)
  }
  return (
    <div
      data-active={isActive}
      data-next={isNext}
      data-previous={isPrevious}
      data-animation-direction={isPrevious ? 'Left' : 'Right'}
      className={styles['carousel-item']}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

const CarouselV2 = ({
  gridItems,
  ItemComponentCarousel,
  ItemComponentMessage,
  withMessages = false,
  variant = 'standard',
  trackingNameCarouselControls = 'Carousel Nav',
}) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  // Retrieving AnalyticsContext for dispatching events
  const { dispatch } = useContext(AnalyticsContext)
  const specificity = useEventScope()

  const [activeIndex, setActiveIndex] = useState(0)
  const minSwipeDistance = 50
  let touchStart = useRef()
  let touchEnd = useRef()

  const dispatchCarouselEvent = ({ event, direction = 'Right', index }) =>
    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: {
        ...handleTrackAndReactEvent(
          event,
          updateTrackWithListedEvent(
            {
              list_name: trackingNameCarouselControls,
              click_id: `${direction} Carousel`,
              click_position: index,
            },
            specificity
          ),
          () => setActiveIndex(index)
        ),
      },
    })

  const onPreviousClick = (event) => {
    // When the current activeIndex is the first item in the list, loop to the end and set the
    // last item in the list as the activeIndex. Otherwise efault to the previous index
    const nextIndex = activeIndex === 0 ? gridItems.length - 1 : activeIndex - 1
    dispatchCarouselEvent({ event, direction: 'Left', index: nextIndex })
  }

  const onNextClick = (event) => {
    // When the current activeIndex is the last item in the list, loop to the beginning and set
    // the first item in the list as the activeIndex. Otherwise set the next item as the activeIndex
    const nextIndex = activeIndex === gridItems.length - 1 ? 0 : activeIndex + 1
    dispatchCarouselEvent({ event, index: nextIndex })
  }

  return (
    <>
      {/* Carousel and Controls Wrapper */}
      <Grid
        container
        direction="column"
        alignItems="center"
        onTouchStart={(e) => {
          touchEnd = null
          touchStart = e.targetTouches[0].clientX
        }}
        onTouchMove={(e) => {
          touchEnd = e.targetTouches[0].clientX
        }}
        onTouchEnd={(e) => {
          if (!touchStart || !touchEnd) return
          const distance = touchStart - touchEnd
          const isLeftSwipe = distance > minSwipeDistance
          const isRightSwipe = distance < -minSwipeDistance

          if (!isLeftSwipe && !isRightSwipe) return
          if (isLeftSwipe) return onNextClick(e)
          onPreviousClick(e)
        }}
        className={styles['carousel-wrapper']}
        style={{
          height: variant === 'standard' ? (isMobile ? '208px' : '364px') : '440px',
        }}
        data-variant={variant}
      >
        {/* Controls Component */}
        {(variant === 'standard' || !isMobile) && (
          <div className={styles['carousel-controls']}>
            <CarouselButton direction={CAROUSEL_DIRECTIONS.PREVIOUS} onClick={onPreviousClick} />
            <CarouselButton direction={CAROUSEL_DIRECTIONS.NEXT} onClick={onNextClick} />
          </div>
        )}

        <div className={styles['carousel-items']}>
          {/* Carousel Items */}
          {gridItems.map((item, index) => (
            <CarouselItem
              key={`carousel-item-${index}`}
              activeIndex={activeIndex}
              itemIndex={index}
              listLength={gridItems.length}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            >
              <ItemComponentCarousel item={item} index={index} activeIndex={activeIndex} />
            </CarouselItem>
          ))}
        </div>
      </Grid>

      {/* Carousel Item Message */}
      {withMessages &&
        gridItems.map((item, index) => (
          <CarouselItemMessage
            key={`carousel-item-message-${index}`}
            activeIndex={activeIndex}
            itemIndex={index}
            listLength={gridItems.length}
          >
            <ItemComponentMessage item={item} />
          </CarouselItemMessage>
        ))}

      <DotIndicators
        classNameIndicators={styles['indicators']}
        size={gridItems.length}
        activeIndex={activeIndex}
        variant="onWhite"
      />
    </>
  )
}

export { CarouselV2 }

export default CarouselV2
